import React, { createContext, useState } from "react";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const [isModal, setModal] = useState(false);
  const [isModalOrderCall, setModalOrderCall] = useState(false);
  const [isModalSuccess, setModalSuccess] = useState(false);
  const [isModalError, setModalError] = useState(false);

  return (
    <AppContext.Provider
      value={{
        isModal,
        setModal,
        isModalOrderCall,
        setModalOrderCall,
        isModalSuccess,
        setModalSuccess,
        isModalError,
        setModalError,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
